.spinner {

  &:before,
  &,
  &:after {
    border-radius: 50%;
    width: 2.0em;
    height: 2.0em;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: load7 1s infinite ease-in-out;
    animation: load7 1s infinite ease-in-out;
  }
  &:before,
  &:after {
    content: '';
    position: absolute;
  }
  &:before {
    left: -3.5em;
    -webkit-animation-delay: -0.40s;
    animation-delay: -0.40s;
  }
  & {
    -webkit-animation-delay: -0.20s;
    animation-delay: -0.20s;
  }
  &:after {
    left: 3.5em;
  }

  /* color: #e7ffd7; */
  text-shadow: 1px 1px 0px #6a6a6a;

  font-size: 10px;
  margin: 0 auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  top: -2em;
}

@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

