.hamburger {
  top: 30px;
}

header .ssi-logo, footer .ssi-logo {
  // width: 214px;
  height: 48px;
}

footer {
  padding-top: 75px;
  .ssi-logo {
    fill: white;
    margin-left: 20px;
  }
  @media (max-width: 760px) {
    footer .footer-hold ul#footer-menu li {
      height: 30px;
    }
  }
  @media (min-width: 761px) and (max-width: 1100px) {
    .footer-hold .col.logo { width: 33%; }
    .footer-hold .col.menu { width: 67%; }
  }
}
  

@media (max-width: 1135px) {
  .container {
    // margin: 0 20px;
  }
}

.block__banner .banner__content {
  svg {
    fill: white;
    position: relative;
  }
  h1 {
    margin: 0 10px;
    .topTitle {
      font-size: 1.15rem;
      font-weight: 100;
      margin-bottom: 0.5rem;
      svg {
        height: 2.05rem;
        top: 0.26rem;
        left: 0.1rem;
      }
    }
    svg {
      height: 5.0rem;
      top: 0.65rem;
      @media (max-width: 760px) and (min-width: 401px) {
        height: 4.0rem;
        top: 0.53rem;
      }
      left: 0.1rem;
    }
    @media (max-width: 400px) {
      font-size: 1.7rem;
      svg {
        height: 3.0rem;
        top: 0.4rem;
      }
    }
  }
}

.block__textarea.is-style-icon-right {
  .media-content { order: 2; }
  .textarea-content { order: 1; }
} 

.textarea-content p {
  text-align: justify;
  text-justify: inter-word;
}

ul li {
  p {
    text-align: left !important;
    margin-top: 0;
  }
  h4 {
    font-weight: normal;
    font-size: 1.2rem;
    margin-bottom: 0.3rem;
    border-bottom: 1px solid #d8d8d8;
    padding-bottom: 0.3rem;
  }
} 

h4 {
  font-weight: normal;
  font-size: 1.30rem;
}

.partners {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .partner {
    margin: 10px;
    display: inline-block;
    text-decoration: none;
    color: black;
    &:hover {
      border-color: #d8d8d8;
      background-color: #f0f0f0;
    }
    &:active { transform: scale(.99); }
    text-align: center;
    border: 1px solid #E6E6E6;
    border-radius: 15px;
    width: 250px;
    @media (max-width: 550px) {
      width: 100%;
    }
    > * {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    background-color: white;
    .logo {
      height: 180px;
      padding: 0rem;
      img {
        max-width: 90%;
        max-height: 130px;
      }
    }
    .name {
      height: 4rem;
      font-weight: bold;
      font-size: 1.2rem;
    }
  }
}

.video-area {
  width: 50%;
  @media (max-width: 1100px) { display: none; }
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
}

.form {
  border: none;
  .col {
    box-sizing: border-box;
    height: 100%;
    margin: 0.5em 1%;
    @media (min-width: 600px) {
      &.half {
        display: inline-block;
        width: 48%;
      }
      // &.third {
      //   display: inline-block;
      //   width: 32%;
      // }
    }
  }
  label {
    display: block;
    text-align: left;
    font-weight: bold;;
    padding: 0 0 5px 5px;
    color: #606060;
  }
  input[type=text], input[type=email], textarea, select {
    box-sizing: border-box;
    border: 1px solid #CCCCCC;
    border-radius: 8px;
    padding: 13px !important;
    display: block;
    font-size: 1rem;
    font-family: "Roboto", sans-serif;
    width: 100%;
  }
  textarea { height: 12rem; }
  button {
    background: none;
    border-radius: 8px;
    font-size: 1rem;
    font-family: "Roboto", sans-serif;
    border: 1px solid #040405;
    color: #040405;
    padding: 13px 45px 12px !important;
    font-weight: 500;
    box-sizing: border-box;
    margin: 2rem auto 0 auto;
    display: block;
    position: relative;
    &:not(:disabled) {
      cursor: pointer;
      &:hover { background: #f8f8f8; }
      &:active:not(&:disabled) { transform: scale(.98); }
      .spinner { display: none; }
    }
    &:disabled {
      .text { visibility: hidden; }
      .spinner {
        display: block;
        position: absolute;
        top: -13px;
        left: 50%;
        transform: translate(-50%, 0);
      }
    }
  }
}

h4 a {
  color: inherit;
  text-decoration: none;
  border-bottom: 1px dotted;
  &:hover { color: #D22F2E; }
}

a:hover {
  transition: all ease 200ms;
}

.side-pic img {
  @media (max-width: 440px) {
    max-width: 100%;
  }
  max-width: 400px;
}

select{
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 99% 50%;
  -moz-appearance: none; 
  -webkit-appearance: none; 
  appearance: none;
  color: black;
}